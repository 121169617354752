<template>
  <Common
    :query="query"
    :read-num-list="readNumList"
    @createRandom="createRandom"
    @handleCreate="handleCreate"
    @handleImageUrl="handleImageUrl"
    @handleLink="handleLink"
    @hanldeRemove="hanldeRemove"
  />
</template>

<script>
  import mixins from './mixins'
  export default {
    mixins: [mixins],
    created() {
      this.query.form_info = ['phone']
      this.query.extra_info = [
        {
          title: null,
          content: null,
          url: null,
        },
      ]
    },
  }
</script>
